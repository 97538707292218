import request from "@/utils/request";

// 获取验证码
export function sendEmailCode(username) {
  return request({
    url: "/users/code?username=" + username,
    headers: {
      isToken: false
    },
    method: "get",
    timeout: 20000
  });
}

//重置密码
export function resetPassword(data) {
  return request({
    url: "/users/password",
    headers: {
      isToken: false
    },
    method: "put",
    data: data
  });
}

//注册
export function registerUser(data) {
  return request({
    url: "/users",
    headers: {
      isToken: false
    },
    method: "post",
    data: data
  });
}

//设置邮箱
export function setEmail(data) {
  return request({
    url: "/users/email",
    method: "post",
    data: data
  });
}

//更新用户信息
export function updateUserInfo(data) {
  return request({
    url: "/users/info",
    method: "put",
    data: data
  });
}
