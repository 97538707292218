import { render, staticRenderFns } from "./Article.vue?vue&type=template&id=c3d89e38&scoped=true"
import script from "./Article.vue?vue&type=script&lang=js"
export * from "./Article.vue?vue&type=script&lang=js"
import style0 from "./Article.vue?vue&type=style&index=0&id=c3d89e38&prod&scoped=true&lang=css"
import style1 from "./Article.vue?vue&type=style&index=1&id=c3d89e38&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c3d89e38",
  null
  
)

export default component.exports