import request from "@/utils/request";

//评论列表
export function getCommentsList(query) {
  return request({
    url: "/comments",
    headers: {
      isToken: false
    },
    method: "get",
    params: query
  });
}

//查看评论下的回复
export function getReplyList(commentId, query) {
  return request({
    url: "/comments/replies/" + commentId,
    headers: {
      isToken: false
    },
    method: "get",
    params: query
  });
}

//添加评论或回复
export function saveComment(data) {
  return request({
    url: "/comments",
    method: "post",
    data: data
  });
}

//点赞评论
export function likeComment(data) {
  return request({
    url: "/comments/like",
    method: "post",
    data: data
  });
}
