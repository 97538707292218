const getters = {
  token: state => state.user.token,
  loginUrl: state => state.user.loginUrl,
  userId: state => state.user.userId,
  avatar: state => state.user.avatar,
  nickname: state => state.user.nickname,
  intro: state => state.user.intro,
  webSite: state => state.user.webSite,
  loginType: state => state.user.loginType,
  email: state => state.user.email,
  articleLikeSet: state => state.user.articleLikeSet,
  commentLikeSet: state => state.user.commentLikeSet,
  searchFlag: state => state.blog.searchFlag,
  loginFlag: state => state.blog.loginFlag,
  registerFlag: state => state.blog.registerFlag,
  forgetFlag: state => state.blog.forgetFlag,
  emailFlag: state => state.blog.emailFlag,
  drawer: state => state.blog.drawer,
  blogInfo: state => state.blog.blogInfo
};
export default getters;
