import request from "@/utils/request";

//标签列表
export function getCategoryList() {
  return request({
    url: "/categories",
    headers: {
      isToken: false
    },
    method: "get"
  });
}
