<template>
  <div>
    <canvas id="starfield" class="message-canvas"></canvas>
    <!-- banner -->
    <div class="message-banner">
      <!-- 弹幕输入框 -->
      <div class="message-container">
        <h1 class="message-title">留言板</h1>
        <div class="animated fadeInUp message-input-wrapper">
          <input
            v-model="messageContent"
            @click="show = true"
            @keyup.enter="addToList"
            placeholder="说点什么吧"
          />
          <button
            class="ml-3 animated bounceInLeft"
            @click="addToList"
            v-show="show"
          >
            发送
          </button>
        </div>
      </div>
      <!-- 弹幕列表 -->
      <div class="barrage-container">
        <vue-baberrage
          :barrageList="barrageList"
          :boxHeight="boxHeight"
          :isShow="barrageIsShow"
          :lanesCount="lanesCount"
          :loop="barrageLoop"
          :messageHeight="messageHeight"
          :throttleGap="throttleGap"
        >
          <template v-slot:default="slotProps">
            <span class="barrage-items">
              <img
                :src="slotProps.item.avatar"
                width="30"
                height="30"
                style="border-radius:50%"
                alt=""
              />
              <span class="ml-2">{{ slotProps.item.nickname }} :</span>
              <span class="ml-2">{{ slotProps.item.messageContent }}</span>
            </span>
          </template>
        </vue-baberrage>
      </div>
    </div>
  </div>
</template>

<script>
import { getMessageList, saveMessage } from "@/api/messages";
export default {
  mounted() {
    this.listMessage();
    this.ini();
    this.makeStars();
    this.interval = setInterval(() => {
      this.drawStars();
    }, 50);
    window.onresize = () => {
      //窗口大小发生变化时重新随机生成星星数据
      this.ini();
      this.makeStars();
      this.interval = setInterval(() => {
        this.drawStars();
      }, 50);
    };
  },
  data() {
    return {
      show: false,
      messageContent: "",
      barrageList: [],
      barrageIsShow: true, //是否展示弹幕
      barrageLoop: true, //是否循环播放
      boxHeight: 800, //高度
      messageHeight: 30, //消息高度
      lanesCount: 20, //泳道数量
      throttleGap: 1000, //消息间隔
      canvas: {},
      context: {},
      stars: [],
      stars_count: 0,
      interval: {}
    };
  },
  methods: {
    ini() {
      //初始化
      this.canvas = document.getElementById("starfield");
      this.canvas.width = window.innerWidth;
      this.canvas.height = window.innerHeight;
      this.context = this.canvas.getContext("2d");
      this.stars = Array(); //数组存放随机生成的星星数据（x,y,大小，颜色，速度）
      this.stars_count = 300; //星星数量
      clearInterval(this.interval);
    },
    makeStars() {
      for (var i = 0; i < this.stars_count; i++) {
        let x = Math.random() * this.canvas.offsetWidth;
        let y = Math.random() * this.canvas.offsetHeight;
        let radius = Math.random() * 0.8;
        let color =
          "rgba(" +
          Math.random() * 255 +
          "," +
          Math.random() * 255 +
          "," +
          Math.random() * 255 +
          ",0.8)";
        let speed = Math.random() * 0.5;
        let arr = { x: x, y: y, radius: radius, color: color, speed: speed }; //（x,y,大小，颜色，速度）
        this.stars.push(arr); //随机生成的星星数据存在这里
      }
    },
    drawStars() {
      this.context.fillStyle = "#0e1729";
      this.context.fillRect(0, 0, this.canvas.width, this.canvas.height);
      for (const element of this.stars) {
        let x = element["x"] - element["speed"];
        if (x < -2 * element["radius"]) x = this.canvas.width;
        element["x"] = x;
        let y = element["y"];
        let radius = element["radius"];
        this.context.beginPath();
        this.context.arc(x, y, radius * 2, 0, 2 * Math.PI);
        this.context.fillStyle =
          "rgba(" +
          Math.random() * 255 +
          "," +
          Math.random() * 255 +
          "," +
          Math.random() * 255 +
          ",0.8)";
        this.context.fill();
      }
    },
    addToList() {
      if (this.messageContent.trim() === "") {
        this.$toast({ type: "error", message: "留言不能为空" });
        return false;
      }
      const userAvatar = this.$store.state.user.avatar? this.$store.state.user.avatar : "https://s2.loli.net/2023/05/26/qXTQ98fVKdte1a7.png";
      const userNickname = this.$store.state.user.nickname ? this.$store.state.user.nickname : "游客";
      const message = {
        avatar: userAvatar,
        nickname: userNickname,
        messageContent: this.messageContent,
        time: Math.floor(Math.random() * (10 - 7)) + 7
      };
      this.barrageList.push(message);
      this.messageContent = "";
      saveMessage(message);
    },
    listMessage() {
      getMessageList().then(res => {
        if (res.flag) {
          this.barrageList = res.data;
        }
      });
    }
  }
};
</script>

<style scoped>
canvas.message-canvas {
  position: fixed; /*设置定位*/
  top: 0;
  left: 0;
  z-index: -1; /*使画布基于最低层*/
  background: #0e1729; /*画布背景色*/
}
.message-banner {
  position: absolute;
  top: -60px;
  left: 0;
  right: 0;
  height: 100vh;
  /*background: #49b1f5 url(https://www.talk-ny.cn/d5ojdj.jpg) no-repeat center
    center;*/
  animation: header-effect 1s;
}
.message-title {
  color: #eee;
  animation: title-scale 1s;
}
.message-container {
  position: absolute;
  width: 360px;
  top: 35%;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 5;
  margin: 0 auto;
  color: #fff;
}
.message-input-wrapper {
  display: flex;
  justify-content: center;
  height: 2.5rem;
  margin-top: 2rem;
}
.message-input-wrapper input {
  outline: none;
  width: 70%;
  border-radius: 20px;
  height: 100%;
  padding: 0 1.25rem;
  color: #eee;
  border: #fff 1px solid;
}
.message-input-wrapper input::-webkit-input-placeholder {
  color: #eeee;
}
.message-input-wrapper button {
  outline: none;
  border-radius: 20px;
  height: 100%;
  padding: 0 1.25rem;
  border: #fff 1px solid;
}
.barrage-container {
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  bottom: 0;
  height: calc(100% -50px);
  width: 100%;
}
.barrage-items {
  background: rgb(0, 0, 0, 0.7);
  border-radius: 100px;
  color: #fff;
  padding: 1px 10px 1px 5px;
  align-items: center;
  display: flex;
  margin: 10px 0;
}
</style>
