<template>
  <meting-js
    server="tencent"
    type="playlist"
    id="9192281361"
    fixed="true"
    autoplay="true"
    loop="all"
    order="random"
    auto="tencent"
    preload="auto"
    list-folded="true"
    list-max-height="500px"
    lrc-type="1"
  >
  </meting-js>
</template>

<script>
// 自定义 meting_api

export default {
  name: "AplayerModel",
  components: {
    // HeadNav
  }
};
</script>
