import request from "@/utils/request";

//搜索文章
export function searchArticle(query) {
  return request({
    url: "/articles/search",
    headers: {
      isToken: false
    },
    method: "get",
    params: query
  });
}

//首页文章
export function getHomeArticles(query) {
  return request({
    url: "/articles",
    headers: {
      isToken: false
    },
    method: "get",
    params: query
  });
}

//归档文章
export function getArchiveArticles(query) {
  return request({
    url: "/articles/archives",
    headers: {
      isToken: false
    },
    method: "get",
    params: query
  });
}

//根据标签或分类查询文章列表
export function getArticlesByTagOrCategory(path, query) {
  return request({
    url: "" + path,
    headers: {
      isToken: false
    },
    method: "get",
    params: query
  });
}

//查询文章详情
export function getArticlesInfo(articleId) {
  return request({
    url: "/articles/" + articleId,
    headers: {
      isToken: false
    },
    method: "get"
  });
}

//查询最新文章列表
export function getArticlesNewest() {
  return request({
    url: "/articles/newest",
    headers: {
      isToken: false
    },
    method: "get"
  });
}

//点赞文章
export function likeArticles(data) {
  return request({
    url: "/articles/like",
    method: "post",
    data: data
  });
}
