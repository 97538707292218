import request from "@/utils/request";
// 登录方法
export function userLogin(username, password, code, uuid) {
  const data = {
    username: username,
    password: password,
    code: code,
    uuid: uuid
  };
  return request({
    url: "/login",
    headers: {
      isToken: false,
      repeatSubmit: false
    },
    method: "post",
    data: data
  });
}

// 退出方法
export function userLogout() {
  return request({
    url: "/logout",
    method: "post"
  });
}

// 获取验证码
export function getCodeImg() {
  return request({
    url: "/captchaImage",
    headers: {
      isToken: false
    },
    method: "get",
    timeout: 20000
  });
}

// 微博登录方法
export function weiboLogin(code) {
  const data = {
    code: code
  };
  return request({
    url: "/users/oauth/weibo",
    headers: {
      isToken: false
    },
    method: "post",
    data: data
  });
}

// QQ登录方法
export function qqLogin(openId, accessToken) {
  const data = {
    openId: openId,
    accessToken: accessToken
  };
  return request({
    url: "/users/oauth/qq",
    headers: {
      isToken: false
    },
    method: "post",
    data: data
  });
}

// 百度登录方法
export function baiduLogin(code) {
  const data = {
    code: code
  };
  return request({
    url: "/users/oauth/baidu",
    headers: {
      isToken: false
    },
    method: "post",
    data: data
  });
}

export function wechatLogin(code) {
  const data = {
    code: code
  };
  return request({
    url: "/users/oauth/wechat",
    headers: {
      isToken: false
    },
    method: "post",
    data: data
  });
}
