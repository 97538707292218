<template>
  <div>
    <div class="user-banner banner">
      <h1 class="banner-title">个人中心</h1>
    </div>
    <v-card class="blog-container">
      <div>
        <span class="info-title">基本信息</span>
      </div>
      <v-row class="info-wrapper">
        <v-col md="3" cols="12">
          <button id="pick-avatar">
            <v-avatar size="140">
              <img :src="this.$store.state.user.avatar" alt="" />
            </v-avatar>
          </button>
          <avatar-cropper
            @uploaded="uploadAvatar"
            trigger="#pick-avatar"
            upload-url="/api/users/avatar"
            @uploading="handleUploading"
          />
        </v-col>
        <v-col md="7" cols="12">
          <v-text-field
            v-model="userInfo.nickname"
            label="昵称"
            placeholder="请输入您的昵称"
          />
          <v-text-field
            v-model="userInfo.webSite"
            class="mt-7"
            label="个人网站"
            placeholder="http://你的网址"
          />
          <v-text-field
            v-model="userInfo.intro"
            class="mt-7"
            label="简介"
            placeholder="介绍下自己吧"
          />
          <div v-if="loginType !== 0" class="mt-7 binding">
            <v-text-field
              disabled
              v-model="email"
              label="邮箱号"
              placeholder="请绑定邮箱"
            />
            <v-btn v-if="email" text small @click="openEmailModel">
              修改绑定
            </v-btn>
            <v-btn v-else text small @click="openEmailModel">
              绑定邮箱
            </v-btn>
          </div>
          <v-btn @click="handleUpdateUserInfo" outlined class="mt-5"
            >修改</v-btn
          >
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import AvatarCropper from "vue-avatar-cropper";
import { getToken } from "@/utils/auth";
export default {
  components: { AvatarCropper },
  data: function() {
    return {
      userInfo: {
        nickname: this.$store.state.user.nickname,
        intro: this.$store.state.user.intro,
        webSite: this.$store.state.user.webSite,
        loginType: this.$store.state.user.loginType
      }
    };
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    handleUploading({ form, request, response }) {
      request.headers.append("Authorization:", "Bearer " + getToken());
    },
    handleUpdateUserInfo() {
      this.$store.dispatch("UpdateUserInfo", this.userInfo).then(res => {});
      this.$toast({ type: "success", message: "修改成功" });
    },
    uploadAvatar(data) {
      if (data.flag) {
        this.$store.dispatch("UpdateAvatar", data.data);
        this.$toast({ type: "success", message: data.message });
      } else {
        this.$toast({ type: "error", message: data.message });
      }
    },
    openEmailModel() {
      this.$store.dispatch('blog/setEmailFlag',true);
    }
  },
  computed: {
    email() {
      return this.$store.state.user.email;
    },
    loginType() {
      return this.$store.state.user.loginType;
    }
  }
};
</script>

<style scoped>
.user-banner {
  background: #49b1f5 url(https://www.talk-ny.cn/article/setting.jpeg) no-repeat
    center center;
}
.info-title {
  font-size: 1.25rem;
  font-weight: bold;
}
.info-wrapper {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
#pick-avatar {
  outline: none;
}
.binding {
  display: flex;
  align-items: center;
}
</style>
