import {getBlogInfo} from "@/api/bloginfo";

const state = {
    searchFlag: false,
    loginFlag: false,
    registerFlag: false,
    forgetFlag: false,
    emailFlag: false,
    drawer: false,
    blogInfo: {}
  }
const mutations ={
    SET_SEARCH_FLAG(state, flag) {
      state.searchFlag = flag;
    },
    SET_LOGIN_FLAG(state, flag) {
      state.loginFlag = flag;
    },
    SET_REGISTER_FLAG(state, flag) {
      state.registerFlag = flag;
    },
    SET_FORGET_FLAG(state, flag) {
      state.forgetFlag = flag;
    },
    SET_EMAIL_FLAG(state, flag) {
      state.emailFlag = flag;
    },
    SET_DRAWER(state, flag) {
      state.drawer = flag;
    },
    SET_BLOG_INFO(state, blogInfo) {
      state.blogInfo = blogInfo;
    }
  }
const actions={
    // 获取博客信息
    BlogInfo({ commit }) {
      return new Promise((resolve, reject) => {
        getBlogInfo().then(response => {
          commit("SET_BLOG_INFO", response.data);
          resolve(response);
        }).catch(error => {
          reject(error)
        })
      })
    },
    setDrawer({ commit }, drawer) {
      commit("SET_DRAWER", drawer);
    },
    setSearchFlag({ commit }, flag) {
      commit("SET_SEARCH_FLAG", flag);
    },
    setLoginFlag({ commit }, flag) {
      commit("SET_LOGIN_FLAG", flag);
    },
    setRegisterFlag({ commit }, flag) {
      commit("SET_REGISTER_FLAG", flag);
    },
    setForgetFlag({ commit }, flag) {
      commit("SET_FORGET_FLAG", flag);
    },
    setEmailFlag({ commit }, flag) {
      commit("SET_EMAIL_FLAG", flag);
    }
}
export default {
  namespaced: true,
  state,
  mutations,
  actions
};
