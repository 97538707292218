export default {
  "[微笑]": "https://www.talk-ny.cn/emoji/weixiao.png",

  "[笑]": "https://www.talk-ny.cn/emoji/xiao.png",

  "[呲牙]": "https://www.talk-ny.cn/emoji/ciya.png",

  "[OK]": "https://www.talk-ny.cn/emoji/ok.png",

  "[鲜花]": "https://www.talk-ny.cn/emoji/xianhua.png",

  "[打脸]": "https://www.talk-ny.cn/emoji/dalian.png",

  "[鄙视]": "https://www.talk-ny.cn/emoji/bishi.png",

  "[大金牙]": "https://www.talk-ny.cn/emoji/dajinya.png",

  "[喜欢]": "https://www.talk-ny.cn/emoji/xihuan.png",

  "[酸了]": "https://www.talk-ny.cn/emoji/suanle.png",

  "[大哭]": "https://www.talk-ny.cn/emoji/daku.png",

  "[害羞]": "https://www.talk-ny.cn/emoji/haixiu.png",

  "[晕了]": "https://www.talk-ny.cn/emoji/yunle.png",

  "[衰]": "https://www.talk-ny.cn/emoji/shuai.png",

  "[无语]": "https://www.talk-ny.cn/emoji/wuyu.png",

  "[大兵]": "https://www.talk-ny.cn/emoji/dabing.png",

  "[调皮]": "https://www.talk-ny.cn/emoji/tiaopi.png",

  "[笑哭]": "https://www.talk-ny.cn/emoji/xiaoku.png",

  "[奸笑]": "https://www.talk-ny.cn/emoji/jianxiao.png",

  "[偷笑]": "https://www.talk-ny.cn/emoji/touxiao.png",

  "[大笑]": "https://www.talk-ny.cn/emoji/daxiao.png",

  "[坏笑]": "https://www.talk-ny.cn/emoji/huaixiao.png",

  "[捂脸]": "https://www.talk-ny.cn/emoji/wulian.png",

  "[发呆]": "https://www.talk-ny.cn/emoji/fadai.png",

  "[抠鼻]": "https://www.talk-ny.cn/emoji/koubi.png",

  "[惊喜]": "https://www.talk-ny.cn/emoji/jingxi.png",

  "[惊讶]": "https://www.talk-ny.cn/emoji/jingya.png",

  "[捂脸哭]": "https://www.talk-ny.cn/emoji/wulianku.png",

  "[黑脸]": "https://www.talk-ny.cn/emoji/heilian.png",

  "[吐血]": "https://www.talk-ny.cn/emoji/tuxue.png",

  "[狗头]": "https://www.talk-ny.cn/emoji/goutou.png",

  "[滑稽]": "https://www.talk-ny.cn/emoji/huaji.png",

  "[吃瓜]": "https://www.talk-ny.cn/emoji/chigua.png",

  "[裂开]": "https://www.talk-ny.cn/emoji/liekai.png",

  "[点赞]": "https://www.talk-ny.cn/emoji/dianzan.png",

  "[鼓掌]": "https://www.talk-ny.cn/emoji/guzhang.png",

  "[尴尬]": "https://www.talk-ny.cn/emoji/ganga.png",

  "[666]": "https://www.talk-ny.cn/emoji/666.png",

  "[咖啡]": "https://www.talk-ny.cn/emoji/kafei.png",

  "[委屈]": "https://www.talk-ny.cn/emoji/weiqu.png",

  "[暗中观察]": "https://www.talk-ny.cn/emoji/azgc.png",

  "[吓]": "https://www.talk-ny.cn/emoji/jingxia.png?",

  "[生病]": "https://www.talk-ny.cn/emoji/shengbing.png",

  "[吐]": "https://www.talk-ny.cn/emoji/tu.png",

  "[疑问]": "https://www.talk-ny.cn/emoji/yiwen.png",

  "[嘘声]": "https://www.talk-ny.cn/emoji/xusheng.png",

  "[捂眼]": "https://www.talk-ny.cn/emoji/wuyan.png",

  "[思考]": "https://www.talk-ny.cn/emoji/sikao.png",

  "[再见]": "https://www.talk-ny.cn/emoji/zaijian.png",

  "[翻白眼]": "https://www.talk-ny.cn/emoji/fanbaiyan.png",

  "[哈欠]": "https://www.talk-ny.cn/emoji/haqian.png",

  "[奋斗]": "https://www.talk-ny.cn/emoji/fendou.png",

  "[墨镜]": "https://www.talk-ny.cn/emoji/mojing.png",

  "[撇嘴]": "https://www.talk-ny.cn/emoji/piezui.png",

  "[难过]": "https://www.talk-ny.cn/emoji/nanguo.png",

  "[抓狂]": "https://www.talk-ny.cn/emoji/zhuakuang.png",

  "[敲打]": "https://www.talk-ny.cn/emoji/qiaoda.png",

  "[困]": "https://www.talk-ny.cn/emoji/kun.png",

  "[生气]": "https://www.talk-ny.cn/emoji/shengqi.png",

  "[爱心]": "https://www.talk-ny.cn/emoji/aixin.png",

  "[胜利]": "https://www.talk-ny.cn/emoji/shengli.png",

  "[感谢]": "https://www.talk-ny.cn/emoji/ganxie.png",

  "[阴险]": "https://www.talk-ny.cn/emoji/yinxian.png",

  "[加油]": "https://www.talk-ny.cn/emoji/jiayou.png"
};
