import request from "@/utils/request";

//留言列表
export function getMessageList() {
  return request({
    url: "/messages",
    headers: {
      isToken: false
    },
    method: "get"
  });
}

//保存留言
export function saveMessage(data) {
  return request({
    url: "/messages",
    headers: {
      isToken: false
    },
    method: "post",
    data: data
  });
}
