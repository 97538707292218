export default {
  QQ_APP_ID: "102049396",
  QQ_REDIRECT_URI: "http://www.talk-ny.cn/oauth/login/qq",
  WEIBO_APP_ID: "3290329776",
  WEIBO_REDIRECT_URI: "http://www.talk-ny.cn/oauth/login/weibo",
  TENCENT_CAPTCHA: "2088053498",
  BAIDU_APP_KEY: "XVDSBMdrLtEtCj2C4BZ70IG1",
  BAIDU_REDIRECT_URI: "http://www.talk-ny.cn/oauth/login/baidu",
  WECHAT_APP_KEY: "wxf268f1b5fb227ad9",
  WECHAT_REDIRECT_URI: "http://www.talk-ny.cn/oauth/login/wechat"
};
