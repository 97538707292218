import request from "@/utils/request";

// 查询首页信息
export function getBlogInfo() {
  return request({
    url: "/",
    headers: {
      isToken: false
    },
    method: "get"
  });
}

// 查询关于我
export function getAbout() {
  return request({
    url: "/about",
    headers: {
      isToken: false
    },
    method: "get"
  });
}

// 上传语音
export function uploadVoice(formData) {
  return request({
    url: "/voice",
    method: "post",
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
}
