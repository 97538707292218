import request from "@/utils/request";

//标签列表
export function getLinkList() {
  return request({
    url: "/links",
    headers: {
      isToken: false
    },
    method: "get"
  });
}
